<template>
  <div class="">
    <b-card class="">
      <div class="mb-1 d-flex justify-content-between">
        <strong>
          Backups
        </strong>
      </div>
      <b-table
        small
        :fields="fields"
        :items="backups"
        responsive="sm"
      >
        <template #cell(actions)="data">
          <hwa-button
            icon="RefreshCcwIcon"
            :icon-only="true"
            variant="flat-success"
            @onClick="showConfirm(data.item.id)"
          />
        </template>

      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BTable, BCard,
} from 'bootstrap-vue'
import HwaButton from '@/layouts/components/HwaButton.vue'
import showToast from '@/mixins/showToast'
import { ValidationObserver } from 'vee-validate'
import HwaModal from '@/layouts/components/HwaModal.vue'
import confirm from '@/mixins/confirm'
import HwaSelect from '@/layouts/components/HwaSelect.vue'

export default {
  components: {
    HwaSelect,
    HwaModal,
    HwaButton,
    BTable,
    BCard,
    ValidationObserver,
  },
  mixins: [showToast, confirm],
  data() {
    return {
      fields: [{key:'name',label:"Name",sortable:true}, {key:'created_at',label:"Created At",sortable:true}, 'actions'],
      backups: [],
    }
  },
  mounted() {
    this.getMembers()
    // this.getRoles()
  },
  methods: {
    showConfirm(id) {
      this.confirmAction( 'Are you sure you want to restore DB?')
        .then(res => {
          if (res) {
            this.restoreDb(id)
          }
        })
    },
    getMembers() {
      this.$http.get('/api/admin/backups')
        .then(res => {
          this.backups = res.data
        })
        .catch(err => {
          this.showError('Failed to retrieve backups')
        })
    },
    restoreDb(id) {
      if (id) {
        this.$http.post(`/api/admin/backups/${id}/restore`, { role_id: this.role_id, member_id: this.member_id })
          .then(res => {
            this.showSuccess('Restored successfully')
          })
          .catch(err => {
            this.showError('Failed to restore')
          })
      } else {
        this.showError('Select Backup')
      }
    },
  },

}
</script>
